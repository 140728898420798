//clear modal links
function stripurl() {
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("/?"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}

function striphash() {
    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}

function geturlid() {
    var uri = window.location.toString();
    var res = '';
    if (uri.indexOf("#") > 0) {
        var id = uri.split('#');
        res = id[1];
    }
    return res;
}

function seturlid(id) {
    var uri = window.location.toString();
    window.history.replaceState({}, document.title, uri + id);
}

function seturlproperty(property) {
    var uri = window.location.toString();
    window.history.replaceState({}, document.title, uri + '?property="' + encodeURIComponent(property) + '"');
}

function getprop() {
    console.log('get prop 1')
    var uri = window.location.toString();
    if (uri.indexOf("?property=") == -1) {
        return false;
    }
    var tmp = uri.split('/?property=');
    res = tmp[1];
    return res;
}
// MSBR "add the pay near me locations "
function paymap() {
    $('#msbrpay').on('show.bs.modal', function(event) {
        var m = $("#msbrpaymap");
        m.empty();
        var maphtml = '<div class="row"><div class="col-12"><div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" style="width:75vw;" src="https://www.google.com/maps/d/embed?mid=1ln9zF7K0bKSBah0iQaX9ekpQ0gc2xpcB&ll=34.731412945500246%2C-92.23731079999999&z=10"></iframe></div></div></div>';
        m.html(maphtml);
    });
}
//animates footer 
AOS.init({
    offset: 0,
    duration: 1300,
    easing: 'ease-in-sine'
});
// controls carousel
// $('#carousel-ourmission').carousel({
//     interval: 30000,
//     wrap: true,
//     keyboard: true
// })
$('#cfcpropsModal1').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        // propname = button.data('propertyname') // Extract info from data-* attributes
        var modal = $(this)
        // modal.find('.modal-body h2').text(propname)
        // var mainim = button.data('mainimage') // Extract info from data-* attributes
        // console.log(mainim);
        // modal.find('.modal-body img').attr('src', mainim);
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        // modal.find('.modal-title').text('New message to ' + propname)
        // modal.find('.modal-body input').val(propname)
        // $.post("_index.php", {
        // prop_n: propname,
        // gettype: 'det' 
        // usernm : cuser
        // }).done(function(data) {
        // $(".alert-danger").css("display", "none");
        // info("Update Successful!, " + primary + "\'s " + column + " was set to " + newVal, true);
        // var parsed = JSON.parse(data);
        // var desc = parsed['res'][0]; 
        // var desc = parsed['res']; 
        // var links = parsed['links'];
        // var imgs = parsed['imgs'];
        // modal.find('.modal-body .p_desc').html(desc); //test, overwrites photo etc
        // modal.find('.modal-body .item-details').html(links);
        // modal.find('.modal-body .prop_images').html(imgs);
        // });    
    })
    // scroll to top button
    ! function(o) {
        "use strict";
        o(document).on("click", "a.page-scroll", function(a) {
            var l = o(this);
            o("html, body").stop().animate({
                scrollTop: o(l.attr("href")).offset().top - 50
            }, 1250, "easeInOutExpo"), a.preventDefault()
        })
    }(jQuery);
// ! function(o) {
//     "use strict";
//     o(document).on("click", "a.page-scroll", function(a) {
//         var l = o(this);
//         o("html, body").stop().animate({
//             scrollTop: o(l.attr("href")).offset().top - 50
//         }, 1250, "easeInOutExpo"), a.preventDefault()
//     }), o("body").scrollspy({
//         target: ".navbar-fixed-top",
//         offset: 54
//     }), o(".navbar-collapse>ul>li>a").click(function() {
//         o(".navbar-collapse").collapse("hide")
//     }), o(window).scroll(function() {
//         o("#mshbmainNav").offset().top > 100 ? o("#mshbmainNav").addClass("navbar-shrink") : o("#mshbmainNav").removeClass("navbar-shrink")
//     }), o(function() {
//         o("body").on("input propertychange", ".floating-label-form-group", function(a) {
//             o(this).toggleClass("floating-label-form-group-with-value", !!o(a.target).val())
//         }).on("focus", ".floating-label-form-group", function() {
//             o(this).addClass("floating-label-form-group-with-focus")
//         }).on("blur", ".floating-label-form-group", function() {
//             o(this).removeClass("floating-label-form-group-with-focus")
//         })
//     })
// }(jQuery);
//handles the testimonials (working block not currenlty neeeded)
// var owl = $('.mshb-testimonials-carousel');
// owl.owlCarousel({
//     items: 1,
//     loop: true,
//     nav: true,
//     margin: 10,
//     autoplay: true,
//     autoplayTimeout: 8000,
//     autoplayHoverPause: true,
//     // animateOut: 'slideOutDown',
//     animateOut: 'zoomOutRight',
//     animateIn: 'zoomInLeft',
//     navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
//     // stagePadding:30,
//     // transitionStyle: "backSlide",
//     smartSpeed: 450
// });
/*!
 * Vitality v2.0.2 (http://themes.startbootstrap.com/vitality-v2.0.2)
 * Copyright 2013-2017 Start Bootstrap
 * Purchase a license to use this theme at (https://wrapbootstrap.com)
 */
/*!
 * Vitality v2.0.0 (http://themes.startbootstrap.com/vitality-v2.0.0)
 * Copyright 2013-2017 Start Bootstrap
 * Purchase a license to use this theme at (https://wrapbootstrap.com)
 */
// Load WOW.js on non-touch devices
var isPhoneDevice = "ontouchstart" in document.documentElement;
$(document).ready(function() {
    if (isPhoneDevice) {
        //mobile
    } else {
        //desktop
        // Initialize WOW.js
        wow = new WOW({
            offset: 50
        })
        wow.init();
    }
});
(function($) {
    "use strict"; // Start of use strict
    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 60)
                }, 1000, "easeInOutExpo");
                //if set to return false, then the url will not change to show scolled id location
                if (this.hash.slice(1) == 'msbrlog') {
                    striphash();
                    return false;
                }
            }
        }
    });
    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function() {
        $('.navbar-collapse').collapse('hide');
    });
    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
        target: '#mshbmainNav',
        offset: 70
    });
    // Collapse the navbar when page is scrolled
    $(window).scroll(function() {
        if ($("#mshbmainNav").offset().top > 100) {
            $("#cityalert").alert('close')
            $("#mshbmainNav").addClass("navbar-shrink");
        } else {
            $("#mshbmainNav").removeClass("navbar-shrink");
            $("#cityalert").alert()
        }
    });
    // Activates floating label headings for the contact form
    // $("body").on("input propertychange", ".floating-label-form-group", function(e) {
    //   $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    // }).on("focus", ".floating-label-form-group", function() {
    //   $(this).addClass("floating-label-form-group-with-focus");
    // }).on("blur", ".floating-label-form-group", function() {
    //   $(this).removeClass("floating-label-form-group-with-focus");
    // });
    // Owl Carousel Settings
    // $(".team-carousel").owlCarousel({
    //   items: 3,
    //   navigation: true,
    //   pagination: false,
    //   navigationText: [
    //     "<i class='fa fa-angle-left'></i>",
    //     "<i class='fa fa-angle-right'></i>"
    //   ],
    // });
    // $(".portfolio-carousel").owlCarousel({
    //   singleItem: true,
    //   navigation: true,
    //   pagination: false,
    //   navigationText: [
    //     "<i class='fa fa-angle-left'></i>",
    //     "<i class='fa fa-angle-right'></i>"
    //   ],
    //   autoHeight: true,
    //   mouseDrag: false,
    //   touchDrag: false,
    //   transitionStyle: "fadeUp"
    // });
    // $(".testimonials-carousel, .mockup-carousel").owlCarousel({
    //   singleItem: true,
    //   navigation: true,
    //   pagination: true,
    //   autoHeight: true,
    //   navigationText: [
    //     "<i class='fa fa-angle-left'></i>",
    //     "<i class='fa fa-angle-right'></i>"
    //   ],
    //   transitionStyle: "backSlide"
    // });
    $(".portfolio-gallery").owlCarousel({
        items: 3,
    });
    // Magnific Popup jQuery Lightbox Gallery Settings
    // $('.gallery-link').magnificPopup({
    //   type: 'image',
    //   gallery: {
    //     enabled: true
    //   },
    //   image: {
    //     titleSrc: 'title'
    //   }
    // });
    // Magnific Popup Settings
    // $('.mix').magnificPopup({
    //   type: 'image',
    //   image: {
    //     titleSrc: 'title'
    //   }
    // });
    // Vide - Video Background Settings
    // $('header.video').vide({
    //   mp4: "mp4/camera.mp4",
    //   poster: "img/agency/backgrounds/bg-mobile-fallback.jpg"
    // }, {
    //   posterType: 'jpg'
    // });
})(jQuery); // End of use strict
//*********
function ap() {
    var propname;
    $('#apmodal').on('show.bs.modal', function(event) {

        var externallink = getprop();
        console.log('the external link' + externallink);
        if(externallink != false)
        {
            propname = getprop();
            propname = decodeURIComponent(getprop());
            if (propname.indexOf("#") > 0) { 
                x = propname.split('#');
                propname = x[0];
                propname = propname.replace(/['"]+/g, '');
            }
            console.log('External link found: ' + propname);
        }
        else
        {
            console.log('looks like a normal page load');
            var button = $(event.relatedTarget) // Button that triggered the modal
            propname = button.data('propertyname') // Extract info from data-* attributes
            // console.log('thebutton is ' + button);
            stripurl();
            striphash();
            seturlproperty(propname);
            seturlid('#apmodal');
        }

        // return


        // if(getprop !== false)
        // {
        // var urlprop = getprop();
        // }
        // console.log('test ' + propname);
        var modal = $(this)
        modal.find('.modal-body h2').text(propname)
        $.post("_index.php", {
            prop_n: propname,
            gettype: 'det'
            // usernm : cuser
        }).done(function(data) {
            // $(".alert-danger").css("display", "none");
            // info("Update Successful!, " + primary + "\'s " + column + " was set to " + newVal, true);
            var parsed = JSON.parse(data);
            var html = parsed['html'];
            // html = 'test from js';
            // var desc = parsed['res'];
            // var links = parsed['links'];
            // var imgs = parsed['imgs'];
            // modal.find('.modal-body .p_desc').html(html); 
            modal.find('.p_desc').html(html);
            // modal.find('.modal-body .item-details').html(links);
            // modal.find('.modal-body .prop_images').html(imgs);
        });
        //     var mainim = button.data('mainimage') // Extract info from data-* attributes
        //     // console.log(mainim);
        //     modal.find('.modal-body img').attr('src', mainim);
        //     // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        //     // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        //     // modal.find('.modal-title').text('New message to ' + propname)
        //     // modal.find('.modal-body input').val(propname)
        //     $.post("_index.php", {
        //         prop_n: propname,
        //         gettype: 'det'
        //         // usernm : cuser
        //     }).done(function(data) {
        //         // $(".alert-danger").css("display", "none");
        //         // info("Update Successful!, " + primary + "\'s " + column + " was set to " + newVal, true);
        //         var parsed = JSON.parse(data);
        //         // var desc = parsed['res'][0]; 
        //         var desc = parsed['res'];
        //         var links = parsed['links'];
        //         var imgs = parsed['imgs'];
        //         modal.find('.modal-body .p_desc').html(desc); //test, overwrites photo etc
        //         modal.find('.modal-body .item-details').html(links);
        //         modal.find('.modal-body .prop_images').html(imgs);
        //     });
        // })
        // $("#intro_seller").click(function(event) {
        //     var r = $('#intro_seller_email')[0].checkValidity();
        //     if (r == true) // if false it won't submit anyway, but it will display native email error message
        //     {
        //         event.preventDefault();
        //         var useremail = $('#intro_seller_email').val();
        //         $('#introduce_form')[0].reset();
        //         $.post("_index.php", {
        //             prop_n: propname,
        //             type: 'emailintro',
        //             email: useremail
        //         }).done(function(data) {
        //             $('#emailsuccess').show();
        //         });
        //     }
    });
}